function centerModalInner(modal: JQuery<HTMLElement>): void {
    modal.css('display', 'block');
    const $dialog = modal.find('.modal-dialog');
    const offset = ($(window).height() - $dialog.height()) / 2;
    // Center modal vertically in window
    $dialog.css('margin-top', offset);
}

function centerModal(): void {
    centerModalInner($(this));
}

$('#myModal').on('show.bs.modal', function(event): void {
    const button = $(event.relatedTarget); // Button that triggered the modal
    const recipient = button.data('action'); // Extract info from data-* attributes
    const modal = $(this);
    $.ajax({
        url: recipient,
        type: 'GET',
        dataType: 'html',
    })
        .done((result): void => {
            const responseDiv = modal.find('#modalContent');
            responseDiv.html(result);
        })
        .fail((xhr, status: string): void => {
            alert(status);
        })
        .always((): void => {
            centerModalInner(modal);
        });
});

$(document).on('show.bs.modal', '.modal', function(): void {
    const zIndex = 1040 + 10 * $('.modal:visible').length;
    $(this).css('z-index', zIndex);
    setTimeout((): void => {
        $('.modal-backdrop')
            .not('.modal-stack')
            .css('z-index', zIndex - 1)
            .addClass('modal-stack');
    }, 0);
});

function centerModals(): void {
    $('.modal:visible').each(centerModal);
}

$(window).on('resize', (): void => {
    centerModals();
});
