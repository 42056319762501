import { OperationOptions } from 'retry';
import promiseRetry from 'promise-retry';
import { FetchSignature } from './FetchSignature';

declare global {
    export type WithRetrySignature = (retryOptions?: OperationOptions) => IFetch;

    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace fetch {
        export let withRetry: WithRetrySignature;
    }

    export interface IFetch extends FetchSignature {
        withRetry: WithRetrySignature;
    }
}

fetch.withRetry = function(retryOptions?: OperationOptions): IFetch {
    const wrapped = (input: RequestInfo, init?: RequestInit): Promise<Response> => {
        const inner = (): Promise<Response> => fetch(input, init);

        if (retryOptions) {
            return promiseRetry(retryOptions, (retry): Promise<Response> => inner().catch(retry));
        } else {
            return promiseRetry((retry): Promise<Response> => inner().catch(retry));
        }
    };
    return Object.assign(wrapped, fetch);
};
