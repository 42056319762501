$((): void => {
    // http://stackoverflow.com/questions/26104214/asp-net-mvc-validation-not-working-with-bootstrap-select-picker
    $.each($('.selectpicker').parents('form'), (_, f): void => {
        $(f).validate().settings.ignore += ':not(select:hidden)';
    });

    (($('.selectpicker').selectpicker() as unknown) as JQuery<HTMLElement>).change(function(): void {
        if (!$('.selectPicker').attr('data-val') == false) {
            $(this).valid();
        }
    });
});
